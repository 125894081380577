const configDescriptions = {
  category: {
    usedInTabs: ["Затраты"],
    affects: ["формирование выпадающих списков в затратах"],
    description: "Используются для редактирования/удаления категорий и операции к этим категориям относящимся(возможно только из существующих уже категории и операций)",
  },
  administration: {
    usedInTabs: ["Расписание занятий", "Задачи"],
    affects: [],
    description:
      "Ответственный, на кого назначается задача, если преподаватель филиала не отметил учащихся или не заполнил тему урока",
  },
  responsibility: {
    usedInTabs: ["Продажи"],
    affects: [],
    description:
      "Ответственный, на кого назначаются лиды, если они поступают с сайтов с сайтов (по фил-ам) и выгрузки csv",
  },
  support: {
    usedInTabs: ["Задачи", "Техподдержка"],
    affects: [],
    description:
      "Ответственный, на кого назначается задача от родителей (с личного кабинета родителя)",
  },
  default_teacher: {
    usedInTabs: ["Группы"],
    affects: [],
    description: "Используется как дефолтный преподаватель при создании группы",
  },
  holidays: {
    usedInTabs: ["При создании договора"],
    affects: ["Стоимость программы в договоре"],
    description: "Выходные и праздничные дни подтягиваются при выборе пользователем расчетного счета при создании договора. Используются при формировании таблицы расчетов( расчет стоимости программы с учетом праздничных и выходных дней)",
  },
};

export default configDescriptions;



