import PaymentsSchedule from "../components/PaymentsSchedule";
// import Groups from "../pages/Groups/GroupsPage";
import ReportsPage from "../pages/Reports/ReportsPage";
import DebtPage from "../pages/Debt/DebtPage";
import LessonsPage from "../pages/Lessons/LessonsPage";
import StudentsKanbanPage from "../pages/StudentsKanban/StudentsKanbanPage";
import JournalPage from "../pages/Journal/JournalPage";
import ContractsPage from "../pages/Contracts/ContractsPage";
// import AttendancePage from "../pages/Attendance/AttendancePage";
import Groups from "../pages/Groups/GroupsPage";
import AttendanceReportsPage from "../pages/AttendanceReports/AttendanceReportsPage";
import WorkingOutPage from "../pages/WorkingOut/WorkingOutPage";
import AccessPage from "../pages/Access/AccessPage";
import CreateParentsPage from "../pages/CreatingCustomers/CreatingCustomersPage";
import AppointmentsPage from "../pages/Appointments/AppointmentsPage";
import SalesPage from "../pages/Sales/SalesPage";
import TopicsAndDatesPage from "../pages/TopicsAndDatesOfClasses/TopicsAndDatesPage";
import TasksKanbanPage from "../pages/TasksKanban/TasksKanbanPage";
import ReportsLinksPage from "../pages/ReportsLinks/ReportsLinksPage";
import ClassSchedulePage from "../pages/ClassSchedule/ClassSchedulePage";
import SettingsPage from "../pages/Settings/SettingsPage";
import PaymentsContainer from "../pages/Payments/PaymentsContainer";

// ----- with icons ----- //

const routesConfig = [
  {
    title: "Продажи/Платежи",
    icon: "BsCurrencyDollar",
    children: [
      {
        component: SalesPage,
        title: "Продажи",
        path: "/crm",
        icon: "BsCurrencyDollar",
        permissions: ["contacts.view"],
      },
      {
        component: TasksKanbanPage,
        title: "Задачи доска",
        path: "/tasks",
        icon: "BsListTask",
        permissions: ["tasks.view", "tasks.add", "tasks.modify"],
      },
      {
        component: ContractsPage,
        title: "Баланс по договору",
        path: "/contracts/:id?/:contractNumber?/:name?",
        icon: "BsFileEarmarkText",
        permissions: ["contract.view"],
      },
      {
        component: PaymentsContainer, // "PaymentsPage" and "CostsPage" are rendered in "PaymentsContainer"
        title: "Платежи",
        path: "/payments",
        icon: "BsCoin",
        permissions: ["payment.view"],
      },
      {
        component: DebtPage,
        title: "Дебиторская задолженность",
        path: "/debt",
        icon: "BsJournalText",
        permissions: ["contract.view", "payment.view", "receivable.view"],
      },
    ],
  },
  {
    title: "Учебный процесс",
    icon: "BsPencilSquare",
    children: [
      {
        component: Groups,
        title: "Группы",
        path: "/groups",
        icon: "BsPeopleFill",
        permissions: ["group.view"],
      },
      {
        component: LessonsPage,
        title: "Расписание занятий",
        path: "/lessons",
        icon: "BsCalendar3",
        permissions: ["lesson.view"],
      },
      {
        component: ClassSchedulePage,
        title: "Формирование расписания",
        path: "/class-schedule",
        icon: "BsLayoutTextSidebarReverse",
        permissions: ["user.view", "user.modify", "user.add"],
      },
      {
        component: StudentsKanbanPage,
        title: "Группы Доска",
        path: "/edit-groups",
        icon: "BsPersonLinesFill",
        permissions: ["group.modify"],
      },
      {
        component: TopicsAndDatesPage,
        title: "Темы и даты занятий(учителя/методисты)",
        path: "/schedule-view",
        icon: "BsCalendar2Day",
        permissions: ["lesson.view"],
      },
      {
        component: WorkingOutPage,
        title: "Отработки",
        path: "/working-out",
        icon: "BsFillCalendarFill",
        permissions: ["contract.view", "group.view"],
      },
      {
        component: AppointmentsPage,
        title: "Назначения и замены",
        path: "/appointments",
        icon: "BsReplyAllFill",
        permissions: ["appointment.view"],
      },
    ],
  },
  {
    title: "Создание пользователя",
    icon: "BsPersonPlusFill",
    children: [
      {
        component: AccessPage,
        title: "Сотрудники",
        path: "/access",
        icon: "BsPersonBoundingBox",
        permissions: [
          "user.view",
          "user.add",
          "user.disabled",
          "access.view",
          "access.modify",
          "access.add",
        ],
      },
      {
        component: CreateParentsPage,
        title: "Родители/Дети",
        path: "/parents-create",
        icon: "BsReddit",
        permissions: ["user.view", "user.modify", "user.add"],
      },
    ],
  },
  {
    title: "Отчеты",
    icon: "BsClipboardData",
    children: [
      {
        component: PaymentsSchedule,
        title: "График поступлений",
        path: "/payments-schedule",
        icon: "BsGraphDown",
        permissions: ["contract.view", "receivable.view"],
      },
      {
        component: ReportsPage,
        title: "Отчеты",
        path: "/reports",
        icon: "BsCardChecklist",
        permissions: ["report.view"],
      },
      {
        component: AttendanceReportsPage,
        title: "Отчет по посещаемости",
        path: "/attendance-reports/:groupID?",
        icon: "BsListCheck",
        permissions: ["report_attendance.view"],
      },
      {
        component: ReportsLinksPage,
        title: "Сcылки/Приказы",
        path: "/reports-links",
        icon: "BsLink",
        permissions: ["links.view"],
      },
    ],
  },

  {
    component: JournalPage,
    title: "Дневник ученика",
    path: "/journal",
    icon: "BsBookHalf",
    permissions: ["personal_area.view"],
  },
  {
    component: SettingsPage,
    title: "Настройки",
    path: "/settings",
    icon: "BsGear",
    permissions: ["configs.view", "configs.modify"],
  }
];

export default routesConfig;