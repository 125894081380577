import React from "react";
import { Button } from "@mui/material";
import { DataGridPro, useGridApiRef } from "../../dataGridLib/x-data-grid-pro";
import GridToolbar from "./GridToolbar";

function DataGrid(props) {
  // eslint-disable-next-line react/destructuring-assignment
  const {
    data,
    columns,
    loading,
    getData,
    addData,
    updateData,
    removeData,
    entityName,
    globalState,
    permissions,
    processRowUpdate,
    onCellEditStop,
  } = props;
  const apiRef = useGridApiRef();

  React.useEffect(() => {
    console.log("propsDataGrid", props);
  }, []);

  // wait for app init
  React.useEffect(() => {
    if (!globalState.loading && getData) getData();
  }, [globalState.loading]);

  const initialGridState = {
    editMode: false,
    selectedRow: "",
  };

  const [gridState, setGridState] = React.useState(initialGridState);

  React.useEffect(() => {
    if (data.length === 0) {
      setGridState(initialGridState);
    }
  }, [data]);

  const handleCellClick = React.useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleDoubleRowClick = React.useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleDoubleCellClick = (params, event) => {
    event.defaultMuiPrevented = true;
    if (!permissions.modify) {
      return;
    }
    const id = gridState.selectedRow;
    apiRef.current.setRowMode(id, "edit");
    setGridState({
      ...gridState,
      editMode: true,
    });
  };

  const handleSelectionChange = (rowId) => {
    const selectedRowId = String(rowId[0]);
    const id = gridState.selectedRow;
    if (gridState.editMode && id && selectedRowId !== id) {
      apiRef.current.setRowMode(id, "view");
      const row = apiRef.current.getRow(id);
      setGridState({
        ...gridState,
        editMode: false,
        selectedRow: selectedRowId,
      });
      if (row && row.isNew) {
        apiRef.current.updateRows([
          {
            id,
            _action: "delete",
          },
        ]);
      }
    } else {
      setGridState({ ...gridState, selectedRow: selectedRowId });
    }
  };

  const handleCellKeyDown = React.useCallback((params, event) => {
    if (["Escape", "Delete", "Backspace", "Enter"].includes(event.key)) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const handleCellFocusOut = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const setDefaultValue = (id) => {
    // get all columns in new/editing row
    const columnsInEditingRow = apiRef.current.getRowParams(id);

    // filter columns for default value
    const columnsForDefaultValue = columnsInEditingRow.columns.filter(
      (obj) => obj.defaultValue
    );

    if (!columnsForDefaultValue.length > 0) return;
    // make array of objects with field and default value
    const fields = columnsForDefaultValue.map((obj) => {
      const { field } = obj;
      const defValue = apiRef.current.getCellValue(id, obj.field);
      return {
        [field]: defValue,
      };
    });

    // update a columns in current row with default values
    fields.map((obj) =>
      Object.entries(obj).map(([key, value]) =>
        apiRef.current.updateRows([
          {
            id,
            [key]: value,
          },
        ])
      )
    );
  };

  const handleCommitRow = (id) => {
    // set default values for columns
    setDefaultValue(id);
    const editingRow = apiRef.current.getRow(id);
    const updatedRow = {
      [entityName]: {
        ...editingRow,
      },
    };

    delete updatedRow[entityName].isNew;

    if (editingRow.isNew) {
      delete updatedRow[entityName].id;
      console.log("updatedRow", updatedRow);
      addData(updatedRow);
    } else {
      updateData(updatedRow);
    }
  };

  const handleRemoveRow = (id) => {
    const idForRemove = {
      [entityName]: id,
    };
    removeData(idForRemove);
  };



  // ------- saving and resetting filters on application pages that use dataGrid  ------- //
  const { dataGridName } = props;
  const savedFiltersKey = `dataGridFilters_${dataGridName}`;
  const [savedFilters, setSavedFilters] = React.useState([]);
  //  create toggle for force re-render
  const [forceUpdateFlag, setForceUpdateFlag] = React.useState(false);
  // fake loading preventing the savedFilters from being overwritten with an empty array.
  const [loadingfilters, setLoadingfilters] = React.useState(true);

  const handleFilterChange = (event) => {
    // console.log('event', event)
    const newFilters = apiRef.current.getVisibleColumns().map((col, index) => ({
      id: index.toString(),
      columnField: col.field,
      operatorValue: col.filterOperators[0], // Assuming the first operator in the list is default
      value: col.filterValue || "",
    }));
    // console.log("newFilters", newFilters);
    const filtered = newFilters.map((item) => {
      event?.items.map((e) => {
        if (item?.columnField === e?.columnField) {
          item.value = e.value;
        }
        return e;
      });
      return item;
    });
    // console.log("filtered", filtered);
    setSavedFilters(filtered);
    localStorage.setItem(savedFiltersKey, JSON.stringify(filtered));
  };

  React.useEffect(() => {
    const savedFiltersJSON = localStorage.getItem(savedFiltersKey);
    if (savedFiltersJSON) {
      // remove the nested object
      const prepared = JSON.parse(savedFiltersJSON).map((item) => {
        const b = item.operatorValue.value;
        item.operatorValue = b;
        return item;
      });
      setSavedFilters(prepared);
    }
    setLoadingfilters(false);
  }, [savedFiltersKey]);

  const handleResetFilters = () => {
    setSavedFilters([]);
    setForceUpdateFlag((prevFlag) => !prevFlag);
    const savedFiltersJSON = localStorage.getItem(savedFiltersKey);
    if (savedFiltersJSON) {
      localStorage.removeItem(savedFiltersKey);
    }
  };



  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState(data.rows);


  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.rows.filter((row) => Object.keys(row).some((field) => searchRegex.test(row[field].toString())));
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(data.rows);
  }, [data.rows]);




  if (loadingfilters) {
    return <div>Loading...</div>;
  }



  return (
    <>
      <div className="table__wrapper" style={{ height: 700 }}>
        <Button variant="contained" color="success" size="small" onClick={handleResetFilters} disabled={savedFilters.length === 0} >сбросить фильтры</Button>
        <DataGridPro
          key={forceUpdateFlag} // this key use for force re-render when key changes
          columns={columns}
          density="compact"
          rows={data}
          editMode="row"
          disableMultipleSelection
          apiRef={apiRef}
          onCellClick={handleCellClick}
          onCellDoubleClick={handleDoubleCellClick}
          onRowDoubleClick={handleDoubleRowClick}
          onCellKeyDown={handleCellKeyDown}
          onCellFocusOut={handleCellFocusOut}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          onSelectionModelChange={handleSelectionChange}
          onFilterModelChange={handleFilterChange}
          processRowUpdate={processRowUpdate}
          onCellEditStop={onCellEditStop}
          initialState={{
            filter: {
              filterModel: {
                items: savedFilters || {},
              },
            },
          }}
          loading={loading}
          {...props}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              apiRef,
              gridState,
              setGridState,
              loading,
              handleCommitRow,
              handleRemoveRow,
              permissions,
              ...props,
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
            },
          }}
          isCellEditable={(params) => {
            if (!permissions.modify && !permissions.add) return false;
            // if editable is set to false, make
            // both new and existing rows non-editable

            // if editable is set to true, and no specific settings for
            // cells, make both new and existing rows editable
            if (
              permissions.modify &&
              params.colDef.editable &&
              params.colDef.editableAfterAdd === undefined &&
              params.colDef.editableOnAdd === undefined &&
              params.colDef.editableWithEmptyValue === undefined &&
              params.colDef.editableOnlyUnknown === undefined
            ) {
              return true;
            }
            if (
              permissions.add &&
              params.row.isNew &&
              params.colDef.editableOnAdd
            ) {
              return true;
            }
            if (
              permissions.modify &&
              !params.row.isNew &&
              params.colDef.editableAfterAdd
            ) {
              return true;
            }
            if (
              params.colDef.editable &&
              !params.colDef.editableWithEmptyValue &&
              !params.value
            ) {
              return false;
            }
            if (
              permissions.modify &&
              params.colDef.editable &&
              params.colDef.editableWithEmptyValue === false &&
              params.value
            ) {
              return true;
            }
            if (
              params.colDef.editableOnlyUnknown &&
              params.value === "unknown"
            ) {
              return true;
            }
            return false;
          }}
        />
      </div>
    </>
  );
}

export default DataGrid;
