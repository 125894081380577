import { settingsApi } from '../../utils/api';

export const getConfigs = (data) => ({
	type: 'GET_CONFIGS',
	payload: data,
	loading: false,
});
export const loadingConfigs = () => ({
	type: 'LOADING_CONFIGS',
	loading: true,
});

export const fetchConfigs = () => (dispatch) => {
	dispatch(loadingConfigs());
	settingsApi.getConfigs().then(({ data }) => dispatch(getConfigs(data)));
};
