/* eslint-disable consistent-return */
const initialState = {
  payload: [],
  loading: true,
};

const scheduleViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COSTS":
      return {
        ...state,
        payload: action.payload,
        loading: action.loading,
      };

    case "ADD_COST":
      return {
        ...state,
        payload: {
          ...state.payload,
          transactions: [...state.payload.transactions, action.payload]
        },
        loading: action.loading,
      };
    case "UPDATE_COST":
      return {
        ...state,
        payload: {
          ...state.payload,
          transactions: state.payload.transactions.map((tr) => tr.id === action.payload.id ? action.payload : tr)

        },
        loading: action.loading,
      };
    case "REMOVE_COST":
      return {
        ...state,
        payload: {
          ...state.payload,
          transactions: state.payload.transactions.filter((item) => item.id !== action.payload)
        },
        loading: action.loading,
      };

    case "LOADING_COSTS":
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default scheduleViewReducer;
