import { journalApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const setJournal = (data) => ({
  type: "SET_JOURNAL",
  payload: data,
  loading: false,
});
export const setSupportHistory = (data) => ({
  type: "SET_SUPPORT_HISTORY",
  payload: data,
  loading: false,
});

export const setError = (data) => ({
  type: "SET_ERROR",
  payload: data,
});

export const loadingJournal = () => ({
  type: "LOADING_JOURNAL",
  loading: true,
});

export const loadingSupportHistory = () => ({
  type: "LOADING_SUPPORT_HISTORY",
  loading: true,
});

export const fetchJournal = (date) => (dispatch) => {
  dispatch(loadingJournal());
  return journalApi.post(date).then(({ data }) => {
    console.log('journal data', data);
    dispatch(setJournal(data));
    notification.success("Данные получены");
  });
};

export const fetchSupportHistory = () => (dispatch) => {
  dispatch(loadingSupportHistory());
  journalApi.getSupportStatus().then(({ ...data }) => {
    dispatch(setSupportHistory(data.data));
  });
};

export const silentSupportHistory = () => (dispatch) => {
  journalApi.getSupportStatus().then(({ ...data }) => {
    dispatch(setSupportHistory(data.data));
  });
  return null;
};


export const getError = (data) => (dispatch) => {
  dispatch(setError(data));
};
