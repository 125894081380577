import React from "react";
import configDescriptions from "./ConfigsDescription";

const renderConfigDetails = (configType) => {
  const { usedInTabs, affects, description } =
    configDescriptions[configType] || {};
  return (
    <div
      style={{
        marginBottom: "10px",
        border: "1px dashed #b0bec5",
        backgroundColor: "#f1f8e9",
        padding: "12px",
        fontSize: "16px",
        color: "#37474f",
        lineHeight: "1.5",
      }}
    >
      <strong style={{ color: "#1b5e20" }}>Описание:</strong>{" "}
      {description || "Нет описания"}
      <div>
        <strong style={{ color: "#33691e" }}>Используется в:</strong>{" "}
        {usedInTabs?.join(", ") || "Неизвестно"}
      </div>
      <div>
        {(affects.length !== 0) && (
          <>
            <strong style={{ color: "#33691e" }}>Влияет на:</strong>{" "}
            {affects.join(", ")}
          </>
        )}
      </div>
    </div>
  );
};

export default renderConfigDetails;
