const initialState = {
  payload: [],
  loading: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONFIGS":
      return {
        ...state,
        payload: action.payload,
        loading: action.loading,
      };
    case "UPDATE_CONFIG":
      return {
        ...state,
        payload: {
          ...state.payload.map((item) => {
            if (item.id === action.payload.id) {
              console.log("i am reducer", action.payload);
            }
            return item;
          })
        },
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default settingsReducer;
