/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable consistent-return */
import React from "react";
import "../scss/Header.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Avatar,
  Button,
  CircularProgress,
  Paper,
  TextField,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { logout } from "../redux/actions/loginActions";
import ContactDialog from "./ModalsForSalesWithAgreementsAndLeads/ContactDialog";
import { fetchContactHistory } from "../redux/actions/contactsActions";
import ParentForLeadsAndContact from "./ModalsForSalesWithAgreementsAndLeads/ParentForLeadsAndContact";
import UserNotifications from "./UserNotifications/UserNotifications";
import TaskModalCreate from "./TaskModal/TaskModalCreate";
// import { findLabelByID } from "../utils/helpers/entitiesHelpers";
import {
  getDefaultUser,
  getPermissions,
} from "../utils/helpers/getDefaultUser";
import useDebounce from "../utils/helpers/debounceHook";
import { contactsApi, journalApi } from "../utils/api";
import TaskModalSupport from "./TaskModal/TaskModalSupport";
import ConnectWebSocketComponent from "./СonnectWebSocket/ConnectWebSocketComponent";

const Header = React.memo(({ user, title }) => {
  const dispatch = useDispatch();

  const hideSidebar = useSelector(({ globalState }) => globalState.hideSidebar);
  const [showSearchBlock, setShowSearchBlock] = React.useState(false);
  const handleLogout = () => {
    dispatch(logout());
  };
  const historyLoading = useSelector(({ contacts }) => contacts.loadingHistory);
  const contactHistory = useSelector(({ contacts }) => contacts.contactHistory);
  const globalLoading = useSelector(({ globalState }) => globalState.loading);
  const permissions = getPermissions();
  const isEmployee = getDefaultUser().employee;

  // searchAutocomplete
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [ContactModalOpen, setContactModalOpen] = React.useState(false);
  const handleContactOpen = () => {
    setContactModalOpen(true);
  };
  const handleContactClose = () => {
    setContactModalOpen(false);
  };

  const [LeadModalOpen, setLeadModalOpen] = React.useState(false);
  const handleLeadCreateOpen = () => {
    setLeadModalOpen(true);
  };
  const handleLeadClose = () => {
    setLeadModalOpen(false);
  };

  const [isModalTaskCreateOpen, setModalTaskCreateOpen] = React.useState(false);
  const handleTaskCreateOpen = () => {
    setModalTaskCreateOpen(true);
  };

  // passing customer information to the modal window
  const [contactFromSearch, setContact] = React.useState([]);
  const [leadFromSearch, setLeadFromSearch] = React.useState(null);

  // ConnectWebSocketComponent();

  React.useEffect(() => {
    const isShow = permissions.includes("tasks.view");
    setShowSearchBlock(isShow);
  }, []);

  const [savedFilteredLeads, setFilteredLeads] = React.useState([]);
  const [savedFilteredContacts, setFilteredContacts] = React.useState([]);

  const [showAll, setShowAll] = React.useState(true);

  React.useEffect(() => {
    // console.log(showAll);
    showAll
      ? setOptions([...savedFilteredLeads, ...savedFilteredContacts])
      : setOptions([
          ...savedFilteredLeads.filter(
            (option) =>
              option.status !== "rejected" &&
              option.status !== "disqualified" &&
              option.status !== "done"
          ),
          ...savedFilteredContacts,
        ]);
  }, [showAll, inputValue]);

  const openedPage = useSelector((state) => state.globalState.currentPage);

  const PaperComponent = ({ children, ...props }) => (
    <Paper {...props} onMouseDown={(event) => event.preventDefault()}>
      {children}
      <Button
        sx={{ justifyContent: "flex-start", pl: 2, fontSize: "12px" }}
        onClick={() => setShowAll((prevState) => !prevState)}
      >
        {showAll ? "показать новые и в работе" : "показать все лиды"}
      </Button>
    </Paper>
  );

  // autocomplete
  const debounceValue = useDebounce(inputValue, 1000);
  const [searchLoading, setSearchLoading] = React.useState(false);

  const search = (val) => {
    setSearchLoading(true);
    contactsApi.search(val).then((response) => {
      // console.log("response", response);
      const leadsUniq = response.data.leads.map((lead) => ({
        ...lead,
        type: "лид",
        uniqueId: lead.id_lead || Math.random(),
      }));
      setFilteredLeads(leadsUniq);
      const contactsUniq = response.data.contacts.map((contact) => ({
        ...contact,
        type: "контакт",
        uniqueId: contact.id_contact || Math.random(),
      }));
      setFilteredContacts(contactsUniq);
      const searchResult = [...contactsUniq, ...leadsUniq];
      setOptions([...searchResult]);
      setSearchLoading(false);
      // console.log("options", options);
    });
  };

  const onChangeHandler = (_event, newValue) => {
    if (!newValue) {
      return;
    }
    setValue(newValue);
    // console.log("newValue", newValue);

    if (newValue.id_lead) {
      setLeadFromSearch(newValue);
      handleLeadCreateOpen();
      // setValue(null);
    }
    if (newValue.hasOwnProperty("id_lead") === false) {
      // setClient(newValue);
      setContact(newValue);
      dispatch(fetchContactHistory(newValue.id_contact));
      historyLoading ? null : contactHistory && handleContactOpen();
    }
  };

  const onInputHandler = (_event, newInputValue) => {
    setInputValue(newInputValue);
  };

  React.useEffect(() => {
    if (debounceValue) {
      search(debounceValue);
    } else {
      setOptions([]);
    }
  }, [debounceValue]);

  const [isOpenMenu, setIsOpenMenu] = React.useState(false);

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const handleMenuItemClick = (item) => {
    if (item === "Create a Lead") handleLeadCreateOpen();
    if (item === "Create a Task") handleTaskCreateOpen();
    setIsOpenMenu(false);
  };

  const menuRef = React.useRef(null);

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpenMenu(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={hideSidebar ? "header fullsize" : "header"}>
      <div className="header__wrapper">
        <div className="search__block show">
          <div className="header__breadcrumbs">{openedPage}</div>

          <div
            className={
              isEmployee === "true" ? "search_input show" : "search_input hide"
            }
          >
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <Autocomplete
                freeSolo
                value={value}
                onChange={onChangeHandler}
                inputValue={inputValue}
                onInputChange={onInputHandler}
                options={options}
                defaultValue=""
                filterOptions={(opt, state) => opt}
                getOptionLabel={(option) =>
                  `${option.uniqueId} - ${option.name} ${option.phone || ""} (${
                    option.type
                  })`
                }
                renderOption={(props, option) =>
                  (
                    <div {...props}>
                      <span style={{ display: "none" }}>{option.uniqueId}</span>
                      {option.name} {option.phone || ""} {option.status} (
                      {option.type})
                    </div>
                  ) || ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="поиск лидов/контактов"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {searchLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                loading={searchLoading}
                loadingText="Поиск..."
                size="small"
                // blurOnSelect
                // clearOnBlur
                PaperComponent={PaperComponent}
                sx={{
                  width: {
                    sm: "100%",
                    lg: "100%",
                  },
                }}
              />
            </div>
          </div>
          <div
            className={
              isEmployee === "true"
                ? "header__create_btns desktop show"
                : "header__create_btns desktop hide"
            }
          >
            <div className="create_lead_btn">
              <Button
                sx={{
                  width: "max-content",
                }}
                variant="contained"
                onClick={handleLeadCreateOpen}
                size="small"
                aria-hidden={false}
              >
                создать лид
              </Button>
            </div>
            <div className="create_task_btn">
              <Button
                sx={{
                  width: "max-content",
                }}
                variant="contained"
                onClick={handleTaskCreateOpen}
                size="small"
              >
                создать задачу
              </Button>
            </div>
          </div>
        </div>

        <div className="user-block">
          <div>{title}</div>
          <div
            className={
              isEmployee ? "notification-block show" : "notification-block hide"
            }
          >
            <UserNotifications numNotifications={100} />
            <Avatar sx={{ width: 32, height: 32, bgcolor: "primary.main" }}>
              <Person />
            </Avatar>
          </div>
          <div className="user-block__body">
            <span className="user-block__name">{user.userName}</span>
            <span className="user-block__logout" onClick={handleLogout}>
              Выйти
            </span>
          </div>
        </div>
        <div className="mobile-menu" ref={menuRef}>
          <Button
            className="mobile-menu_btn"
            variant="contained"
            onClick={toggleMenu}
          >
            меню
          </Button>

          {isOpenMenu && (
            <ul className="menu-list">
              <li>
                <span className="user_account">АККАУНТ</span>
                <br />
                <span className="user-block__name_mobile">{user.userName}</span>
                <br />
                <span className="user-block__email_mobile">
                  {user.userEmail}
                </span>
                <br />
              </li>
              {isEmployee === "true" ? (
                <>
                  <li onClick={() => handleMenuItemClick("Create a Lead")}>
                    создать Лид
                  </li>
                  <li onClick={() => handleMenuItemClick("Create a Task")}>
                    создать задачу
                  </li>
                </>
              ) : null}
              <li>
                <span className="user-block__logout" onClick={handleLogout}>
                  <span>
                    {" "}
                    <DoNotDisturbIcon fontSize="inherit" />{" "}
                  </span>{" "}
                  Выйти
                </span>
              </li>
            </ul>
          )}
        </div>
      </div>
      <ContactDialog
        open={ContactModalOpen}
        onClose={handleContactClose}
        contactFromContactDialog={contactFromSearch}
        contactHistory={contactHistory}
      />
      <ParentForLeadsAndContact
        open={LeadModalOpen}
        onClose={handleLeadClose}
        lead={leadFromSearch}
        type="lead"
      />
      {!globalLoading && (
        <TaskModalCreate
          task={[]}
          open={isModalTaskCreateOpen}
          onClose={() => setModalTaskCreateOpen(false)}
        />
      )}
    </div>
  );
});

export default Header;
