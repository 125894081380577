/* eslint-disable no-nested-ternary */
import React from "react";
import { Button, Divider } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import CustomLink from "../../components/Link/CustomLink";
import { DateRangePickerCustom } from "../../components/ReusableInputs/ReusableInputs";
import { getUserLocation } from "../../utils/helpers/getDefaultUser";
import { reportsLinksApi } from "../../utils/api";
import "./ReportsLinksPage.scss";
import downloadFile from "../../utils/helpers/downloadFile";
import { setPageTitle } from "../../redux/actions/globalActions";

const ReportsLinksPage = () => {
  const [location, setLocation] = React.useState("");
  const [dateRange, setDateRange] = React.useState({
    from: dayjs(new Date()),
    to: dayjs(new Date()),
  });
  const handleLocationChange = (locationId) => {
    setLocation(locationId);
  };
  const [avaliableLocations, setAvaliableLocations] = React.useState("");
  const [textmessage, setTextmessage] = React.useState("");
  const dispatch = useDispatch();

  const handleDateRangeChange = (date) => {
    setDateRange(date);
  };

  React.useEffect(() => {
    dispatch(setPageTitle("Отчеты/Приказы"));
  }, []);

  const checkAvailableLocations = () => {
    const userLocations = getUserLocation();
    const targetLocations = ["3", "24"];
    if (userLocations) {
      const matchedLocations = userLocations.filter((loc) =>
        targetLocations.includes(loc)
      );
      if (matchedLocations.length > 0) {
        setAvaliableLocations(matchedLocations.join(", "));
      } else {
        setTextmessage("У вас нет доступных локаций");
      }
    }
  };

  React.useEffect(() => {
    checkAvailableLocations();
  }, []);

  const handlePeriodSend = () => {
    if (!location || !dateRange) {
      return;
    }
    const data = {
      location,
      from: dayjs(dateRange.from).format("YYYY-MM-DD"),
      to: dayjs(dateRange.to).format("YYYY-MM-DD"),
    };
    reportsLinksApi
      .generateOrders(data)
      .then((res) => {
        downloadFile(
          res,
          "default_name.docx",
          `приказ ${dayjs().format("YYYY-MM-DD")}.docx`
        );
      })
      .catch((error) => {
        console.error("Download failed:", error);
      });
  };
  return (
    <div className="links-page-wrapper">
      <div>
        <p>
          Журнал с проведенными темами занятий:{" "}
          <CustomLink
            url="https://lookerstudio.google.com/u/0/reporting/1594162d-f2bc-458e-89f1-5b507f164b60/page/lRalD"
            label="lookerstudio журнал занятия"
          />
        </p>
        <p>
          Отчет по проведенным занятиям:{" "}
          <CustomLink
            url="https://lookerstudio.google.com/u/0/reporting/054e93af-318a-4e02-8570-0f69f54ad6bb/page/e7ObD"
            label="lookerstudio отчет занятия"
          />
        </p>
        <p>
          Расписание, которое автомачически генерируется из Назначений,
          сделанных в CRM (обновляется ежедневно):{" "}
          <CustomLink
            url="https://docs.google.com/spreadsheets/d/1wYPmtiXzXhpwo32_GMgNxzXI2-vNWsH8H0yo1DM8Hbc/edit#gid=810940038"
            label="google таблица расписание"
          />
        </p>
        <p>
          Отчет о доходах/расходах:{" "}
          <CustomLink
            url="https://lookerstudio.google.com/reporting/4cae482a-d907-4053-abc5-78e1a4a1b00b/page/tEnnC/edit?s=u5tXUyF0f1M"
            label="lookerstudio доходы/расходы"
          />
        </p>
        <p>
          Выгрузка лидов:{" "}
          <CustomLink
            url="https://docs.google.com/document/d/1bDtWBeNF-D44cKO2v0Y8OqekJ_4YBL0B-dlbb7fwTeo/edit?tab=t.0"
            label="document выгрузка лидов"
          />
        </p>
        <p>
          Часто задаваемые вопросы:{" "}
          <CustomLink
            url="https://docs.google.com/document/d/1DkA7CZ-osXZ8I0ViQUlbVEhDsK2FHivJWOcfvfU-MdE/edit"
            label="document FAQ"
          />
        </p>
      </div>
      {textmessage && <p>{textmessage}</p>}
      <div className={textmessage ? "orders-hide" : "orders-show"}>
        <Divider />
        <div className="reports-orders-title">сформировать приказ </div>
        <div className="reports-box">
          <div className="reports-pick-btns">
            <p>выберите локацию:</p>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleLocationChange("3")}
              disabled={!avaliableLocations.includes("3")}
              style={{
                backgroundColor: location === "3" ? "#4CAF50" : "#2999DD",
                marginRight: "15px",
              }}
            >
              Минск
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleLocationChange("24")}
              disabled={!avaliableLocations.includes("24")}
              style={{
                backgroundColor: location === "24" ? "#4CAF50" : "#2999DD",
              }}
            >
              Барановичи
            </Button>
            <p>выберите период:</p>
            <DateRangePickerCustom
              handleDateRangeChange={handleDateRangeChange}
            />
          </div>

          <div className="reports-send-info">
            <p style={{ color: location ? "inherit" : "red" }}>
              Выбрана локация:{" "}
              {location === "3"
                ? "Минск"
                : location === "24"
                ? "Барановичи"
                : ""}
            </p>
            <p style={{ color: dateRange ? "inherit" : "red" }}>
              Выбран период: {dayjs(dateRange.from).format("DD.MM.YYYY")} -{" "}
              {dayjs(dateRange.to).format("DD.MM.YYYY")}
            </p>
            <div>
              <Button
                variant="contained"
                size="small"
                onClick={handlePeriodSend}
              >
                {" "}
                Получить{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsLinksPage;
