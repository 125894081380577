import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import JsonEditor from "../../components/ConfigsView/JsonEditor";

const JsoneEditorContainer = () => {
  const configs = useSelector((state) => state.settings.payload);
  console.log("configasInEditor", configs);

  const handleSave = (updatedConfig) => {
    // Send the updated configuration back to the server
    alert("отправлено");
  };

  if (!configs) return <p>Loading...</p>;

  return (
    <div>
      <JsonEditor initialData={configs} onSave={handleSave} />
    </div>
  );
};

export default JsoneEditorContainer;
